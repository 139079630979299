
import React from 'react';
import {HeroContainer,HeroWrapper, HeroBg,imageA, HeroH1, HeroP, BtnWrap} from './ProductionElement.js';
//import Icon1 from '../../images/maize1.png';
import Video from '../../videos/production.mp4'
import {Button ,Typography } from "antd";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css' 

const { Title } = Typography;


const ProductionSection = ({title,headline,buttonText}) => {

    return (
        <HeroContainer id="heroContainer">
        <HeroBg>
            {/* <VideoBg autoPlay loop muted src={"https://zicofevideos.s3.us-east-1.amazonaws.com/promotional%20videos/zicofe%20promo%20video.MP4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIEgI1Mny2uiNBrAjMNxMg3OcaShGiC7Jl9lNBrRNdcCfAiEAuCGQ58aDb056Ud4DnKX0JoFYRyy0dj3MCH41bewWRf8q5AIILhAAGgw4MjUwMjMyODUwODEiDGa5O66h1EaZQJtBGyrBAm6wcm5NmK5Wfw6E3DIEa0d11eIKxVvnFU3gBSs3sute1j1FA9VKJrSLNKKR%2FIc9hGb0OEHmzeDMYDskipaqfgSY4jxVAH7rQ6HHVXLkcrKI3BChVdGyAh8Qge7wVGMrp4b%2FhGxGfHeebdj%2BpJoD0hMyuJ2mtX5P18KSCfqEgeVF2QW1TUnJHqTqlwfDZYnC%2F6C11jv3G6U2yX5fpa%2F0l%2FwebuUq%2F7LHONGkUVfejNLB3gv6bz962xyorvxGZ%2BLqloCN5tMWAKLPrnI6PiV%2BTM0N3BTWRN5BvGG9B9ayzprpZOeMSsrO1w8X7gPmwxOLyRpiKbj9W6DVXTVCjhE8EpuNrsulvXqkFkoFUQwwN5Xmry32cF5wCqdYOyB%2FSRB0nbQqD9ntVeyDeM%2FMSvD6SM%2Bh4CCP69NoCyUrjzxnZk8uFjD%2Fvt6PBjqzAi7ICi7Bet0MHFQyTRHCj8qi9%2Fjqz9BI1MyUXd1KyLSGDuZyLl%2Bpx2O6jH3pMiClIwWkCgXkhOT0BhyxdziBEt57fDRFH9mWQV6z1OU%2BLB6%2BfmvrzIIpSeLt4QOU7gk%2FFjsIIyEFQqa7XTYd7xyaY7omXcurbB8HzyHWxWUYmYFMaqHOlPJFtW80acjq1AGFuLRiNOuj2oKkJ%2FVojiFzeS%2B2Bqk6NrjqXHN0UX9La%2BNXdL433nychpXhxLNTD5zkqpL6cxzKD%2FU2slg3pDMOTWsrGhbC2%2FLp6U6OOxWuNJcOQfQ4Klvw%2BDpduX0sIb5SRcYLsZkSVB6nKNO6F0XpM6NUPoyOnoxk8GDEruFSBN0wyfiE7dJBLRWK%2Bc6rLG%2FcmAdDv6qqGqZ39ZoJMJ9AZc4kiXA%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220131T132604Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIA4AFZYI5MX7Q4MYBP%2F20220131%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=3d4c50c37166516378cdfd8e1b146aab21d05e732b4837a001112676ab727748"} type='video/mp4'    /> */}
            {/*<VideoBg autoPlay loop muted src={"https://d322abd807wvow.cloudfront.net/promotional%20videos/zicofe%20with%20narration%20and%20manager%20whatsup.MOV"} type='video/quicktime'    /> */}
            <img src='https://d322abd807wvow.cloudfront.net/zicofe-photos/factoryOptimized.jpg' alt='Zicofe primary processing'></img>

        </HeroBg>

        <HeroWrapper>
            <HeroH1> <Title level={2} style={{color:"#F7F2EF",borderColor:"6px 6px 6px 6px black",fontSize:"28px",lineHeight:"1.07143",fontWeight:"600",letterSpacing:"-.005em",marginTop:"6px",fontFamily:"Lato,Roboto"}}>{title}</Title></HeroH1>
            <HeroP> <Title level={5} style={{color:"#F7F2EF",fontSize:"21px",lineHeight:"1.10722",fontWeight:"400",letterSpacing:".004em",marginTop:"6px",fontFamily:"Lato,Roboto"}}>{headline}</Title></HeroP>
        
            <BtnWrap>
            <Button type="link" size="large" href='/ContactUs' style={{color:"forestgreen",background:"#fff",marginTop:"12px",
                       borderRadius:"6px",minWidth:"100px" }}>Learn More </Button>
            </BtnWrap>

        </HeroWrapper>

           {/* <HeroWrapper id="wrapper">

        <HeroContent id="heroContent">
                      <HeroImageCard />
              </HeroContent>

        </HeroWrapper> */}
        </HeroContainer>

    )
}

export default ProductionSection